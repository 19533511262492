<template>
<div class="home container">

    <h1>Wetter</h1>
    <h2>Steyr</h2>
    <img :src="icon" />
    <div class="temperature">{{result?.data?.main.temp}}°C</div>
    <hr />
    <h3>Vorschau</h3>
    
    <div class="row">
        <div class="col-lg-3 mb-5" v-for="(item, index) in forecast?.data?.list" :key="index">

            <div class="card">
                <div class="card-body">
                    <h5 class="card-title text-muted">{{getFormattedDate(item.dt_txt)}}</h5>
                    <div class="temperature">
                        {{item.main.temp}} °C
                    </div>
                    <img :src="geticonurl(item.weather[0].icon)" />

                </div>
            </div>

        </div>
    </div>

</div>
</template>

<script>
// @ is an alias to /src
//https://api.openweathermap.org/data/2.5/weather?q=Steyr,at&APPID=3e2d48e0e745e4f076ca29911a24bc39&units=metric&lang=de

//icons
//https://openweathermap.org/img/wn/10d@2x.png

import axios from 'axios';

export default {

    data() {
        return {
            result: {},
            forecast: {},
            icon: ''
        }
    },

    mounted() {
        this.load();

    },
    methods: {
        getFormattedDate(date){
            return this.$moment(date).format("DD. ddd HH:mm");
        },
        geticonurl(icon) {
            let url = "https://openweathermap.org/img/wn/" + icon + "@2x.png"
            return url;
        },
        async load() {
            this.result = await axios.get("https://api.openweathermap.org/data/2.5/weather?q=Steyr,at&APPID=3e2d48e0e745e4f076ca29911a24bc39&units=metric&lang=de")
            this.icon = this.geticonurl(this.result.data.weather[0].icon);

            this.forecast = await axios.get("https://api.openweathermap.org/data/2.5/forecast?q=Steyr,at&APPID=3e2d48e0e745e4f076ca29911a24bc39&units=metric&lang=de")

        }
    }
}
</script>

<style scoped>
.temperature {
    font-size: 2em;
}
</style>
